import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {
        setNotifications: (state, action) => {
            state.data = action.payload
        },
        setNotificationsLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setDeletNotificationsLoading: (state: any, action) => {
            state.deleteLoading = action.payload
        },
        setNotificationsError: (state: any, action) => {
            state.error = action.payload
        }
    }
});

export const { 
        setNotifications, 
        setNotificationsLoading, 
        setNotificationsError,
        setDeletNotificationsLoading 
    } = notificationSlice.actions;

export default notificationSlice.reducer;

