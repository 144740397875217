import { useDispatch } from 'react-redux';
import axios from  '../utils/axiosConfig';
import { notify } from 'react-notify-toast';
import { 
        setResources, 
        setResourcesError, 
        setResourcesLoading,
        setShowResourcesEdit,
        setResourcesbyIdsLoading,
        setResourcesbyIdsError,
        setResourcesbyIds,
        setResourcesbySkill,
        setResourcesbySkillLoading,
        setResourcesbySkillError,
        setCapacityPlanningRes, 
        setUnassignedTasksLoading,
        setUnassignedTasksError,
        setUnassignedTasks,
        setResetUnassignedTasks,
        setSuggestedTasksRes,
        setUnassignedAdditionalTasks 
    } from '../redux/slices/resources';
interface Resources {
    message: string;
    project_id: string;
    short_description: string;
    success: boolean;
}


const useResourcesService = () => {
    const dispatch = useDispatch();

    const showError  = (error:any) =>{
        if(error?.response?.data?.error){
            notify.show(error.response.data.error, "error", 3000);
        }
        if(typeof error?.response?.data?.detail === "string" && error?.response?.data?.detail){
            notify.show(error.response.data.detail, "error", 3000);
        }
        if(error.message){
            notify.show(error.message, "error", 3000);
        }
        
    }

    const fetchResources = async (org_id:any) => {
        dispatch(setResourcesLoading(true));
        try {
            const response = await axios.get<Resources[]>(`/organization/${org_id}/resources`);
            dispatch(setResources(response?.data?.resources));
        } catch (error) {
            showError(error)
            dispatch(setResourcesError(error));
        } finally {
            dispatch(setResourcesLoading(false));
        }
    };
    const addResource = async (payload:any) => {
        dispatch(setResourcesLoading(true));
        try {
            const response = await axios.post(`/resources`, payload);
            notify.show(response.data.message, "success", 3000);
            fetchResources(payload.org_id);
            dispatch(setShowResourcesEdit(false));
        } catch (error) {
            showError(error)
            dispatch(setResourcesError(error));
        } finally {
            dispatch(setResourcesLoading(false));
        }
    };
    const getResourcesbyIds = async (projectId:any,payload:any) => {
        dispatch(setResourcesbyIdsLoading(true));
        try {
            const response = await axios.post(`/resources/getResourcesInfo`, payload);
            dispatch(setResourcesbyIds({[projectId]:response.data}));
        } catch (error) {
            showError(error)
            dispatch(setResourcesbyIdsError(error));
        } finally {
            dispatch(setResourcesbyIdsLoading(false));
        }
    };
    const getResourcesbySkill = async (skill_id:string) => {
        dispatch(setResourcesbySkillLoading(true));
        setResourcesbySkill({})
        try {
            const response = await axios.get(`/resources/getResourcesById/${skill_id}`);
            dispatch(setResourcesbySkill(response.data));
        } catch (error) {
            showError(error)
            dispatch(setResourcesbySkillError(error));
        } finally {
            dispatch(setResourcesbySkillLoading(false));
        }
    };
    const updateResource = async (payload:any) => {
        dispatch(setResourcesLoading(true));
        try {
            const response = await axios.put(`/resources/${payload.id}`, payload);
            notify.show(response.data.message, "success", 3000);
            fetchResources(payload.org_id);
            dispatch(setShowResourcesEdit(false));
        } catch (error) {
            showError(error)
            dispatch(setResourcesError(error));
        } finally {
            dispatch(setResourcesLoading(false));
        }
    };

    const fetchCapacityPlanning = async () => {
        dispatch(setResourcesLoading(true));
        try {
            const response = await axios.get<Resources[]>(`/resources/capacity_planning`);
            dispatch(setCapacityPlanningRes(response?.data));
        } catch (error) {
            showError(error)
            dispatch(setResourcesError(error));
        } finally {
            dispatch(setResourcesLoading(false));
        }
    };

    const getUnassignedTasks = async (payload:any) => {
        dispatch(setUnassignedTasksLoading(true));
        try {
            const response = await axios.post(`/resources/unassignedTasks`, payload);
            dispatch(setUnassignedTasks(response?.data?.tasks));
            dispatch(setUnassignedAdditionalTasks(response?.data?.additional_tasks));
        } catch (error) {
            showError(error)
            dispatch(setUnassignedTasksError(error));
        } finally {
            dispatch(setUnassignedTasksLoading(false));
        }
    };
    const saveAssignedTask = async (payload:any,isFetchCapacityPlanning?:boolean) => {
        dispatch(setUnassignedTasksLoading(true));
        try {
            const response = await axios.post(`/resources/assignTask`, payload);
            notify.show(response.data.message, "success", 3000);
            fetchCapacityPlanning();
            if(isFetchCapacityPlanning){
                fetchSuggestedTaskOnOpenSlot();
            }
        } catch (error) {
            showError(error)
            dispatch(setUnassignedTasksError(error));
        } finally {
            dispatch(setUnassignedTasksLoading(false));
        }
    };
    const removeAssignedTask = async (payload:any) => {
        dispatch(setUnassignedTasksLoading(true));
        dispatch(setResetUnassignedTasks());
        try {
            const response = await axios.post(`/resources/removeTask`, payload);
            notify.show(response.data.message, "success", 3000);
            fetchCapacityPlanning();
        } catch (error) {
            showError(error)
            dispatch(setUnassignedTasksError(error));
        } finally {
            dispatch(setUnassignedTasksLoading(false));
        }
    };

    const fetchSuggestedTaskOnOpenSlot = async () => {
        dispatch(setResourcesLoading(true));
        try {
            const response = await axios.get<Resources[]>(`/resources/suggest_task_on_open_slot`);
            dispatch(setSuggestedTasksRes(response?.data));
        } catch (error) {
            showError(error)
            dispatch(setResourcesError(error));
        } finally {
            dispatch(setResourcesLoading(false));
        }
    };
    const clearSuggestedTaskOnOpenSlot = async () => {
        try {
            dispatch(setSuggestedTasksRes({}));
        } catch (error) {
            showError(error)
        } finally {
        }
    };

    return { 
        fetchResources,updateResource,
        addResource,getResourcesbyIds,
        getResourcesbySkill,fetchCapacityPlanning,
        getUnassignedTasks,saveAssignedTask,removeAssignedTask,
        fetchSuggestedTaskOnOpenSlot,clearSuggestedTaskOnOpenSlot
    };
};

export default useResourcesService;