import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { isBrowser, isSmallScreen } from "../helpers/screens";


interface SidebarContextProps {
  isOpenOnSmallScreens: boolean;
  isPageWithSidebar: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpenOnSmallScreens: (isOpen: boolean) => void;
}

const SideNavContext = createContext<SidebarContextProps>(undefined!);

const  SideNavProvider = ({ children }: PropsWithChildren) => {
  const location = isBrowser() ? window.location.pathname : "/";
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if(window.localStorage.getItem("isSidebarOpen")){
      window.localStorage.setItem("isSidebarOpen", "true");
      setOpen(isBrowser() ? true : false)
    } else {
      setOpen(isBrowser() ? window.localStorage.getItem("isSidebarOpen") === "true" : false)
    }
  }, []);

  // Save latest state to localStorage
  useEffect(() => {
    window.localStorage.setItem("isSidebarOpen", isOpen.toString());
  }, [isOpen]);

  // Close Sidebar on page change on mobile
  useEffect(() => {
    if (isSmallScreen()) {
      setOpen(false);
    }
  }, [location]);

  // Close Sidebar on mobile tap inside main content
  useEffect(() => {
    function handleMobileTapInsideMain(event: MouseEvent) {
      const main = document.querySelector("main");
      const isClickInsideMain = main?.contains(event.target as Node);

      if (isSmallScreen() && isClickInsideMain) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleMobileTapInsideMain);
    return () => {
      document.removeEventListener("mousedown", handleMobileTapInsideMain);
    };
  }, []);

  return (
    <SideNavContext.Provider
      value={{
        isOpenOnSmallScreens: isOpen,
        isPageWithSidebar: true,
        setOpenOnSmallScreens: setOpen,
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
}
export default SideNavProvider;

export function useSideNavContext(): SidebarContextProps {
  const context = useContext(SideNavContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useSidebarContext should be used within the SidebarContext provider!"
    );
  }

  return context;
}
