import { createSlice } from '@reduxjs/toolkit';

export const chatMessageSlice = createSlice({
    name: 'chatMessage',
    initialState: {
        messages: [],
        loading: false,
        error: null,
        chatSubquestions: [],
        chatSubquestionsLoading: false,
        chatSubquestionsError: null,
        lastActiveQuestionsLoading: false,
        chatLastActiveQuestionsError: null,
        lastActiveQuestions: [],
        lastActiveChatId:'',
        isChatModalOpen:false,
        chatHistoryList: [],
        chatHistoryListLoading: false,
        setNewChatIdLoading: false,
        setNewChatId: '',

    },
    reducers: {
        setChatMessages: (state: any, action) => {
            state.messages = [...state.messages,{text:action?.payload}]
        },
        setChatMessagesLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setChatMessagesError: (state: any, action) => {
            state.error = action.payload
        },
        setChatSubquestions: (state: any, action) => {
            state.chatSubquestions = action?.payload
        },
        setChatSubquestionsLoading: (state: any, action) => {
            state.chatSubquestionsLoading = action.payload
        },
        setChatSubquestionsError: (state: any, action) => {
            state.chatSubquestionsError = action.payload
        },
        setLastActiveChatId: (state: any, action) => {
            state.lastActiveChatId = action?.payload
        },
        setLastActiveQuestions: (state: any, action) => {
            state.lastActiveQuestions = action?.payload
        },
        setChatLastActiveQuestionsLoading: (state: any, action) => {
            state.lastActiveQuestionsLoading = action.payload
        },
        setChatLastActiveQuestionsError: (state: any, action) => {
            state.chatLastActiveQuestionsError = action.payload
        },
        setBigChatboxToggle: (state: any, action) => {
            state.isChatModalOpen = action.payload
            state.lastActiveQuestions = []
        },
        setChatHistoryListLoading: (state: any, action) => {
            state.chatHistoryListLoading = action.payload
        },
        setChatHistoryListError: (state: any, action) => {
            state.chatHistoryListError = action.payload
        },
        setChatHistoryList: (state: any, action) => {
            state.chatHistoryList = action.payload
        },
        setChatMessageByIdLoading: (state: any, action) => {
            state.chatMessageByIdLoading = action.payload
        },
        setChatMessageByIdError: (state: any, action) => {
            state.chatMessageByIdError = action.payload
        },
        setChatMessageById: (state: any, action) => {
            state.chatMessageById = action.payload
        },
        setNewChatIdLoading: (state: any, action) => {
            state.newChatIdLoading = action.payload
        }
    }
});

export const { 
    setChatMessages, setChatMessagesLoading, setChatMessagesError, 
    setChatSubquestions, setChatSubquestionsLoading, setChatSubquestionsError,
    setChatLastActiveQuestionsLoading,
    setLastActiveQuestions,
    setChatLastActiveQuestionsError,
    setLastActiveChatId,
    setBigChatboxToggle,
    setChatHistoryList, 
    setChatHistoryListError, 
    setChatHistoryListLoading,
    setChatMessageById, 
    setChatMessageByIdError, 
    setChatMessageByIdLoading,
    setNewChatIdLoading  
} = chatMessageSlice.actions;

export default chatMessageSlice.reducer;

