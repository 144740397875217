import { useDispatch } from 'react-redux';
import axios from '../utils/axiosConfig';
import { notify } from 'react-notify-toast';
import { 
        setNotifications, 
        setNotificationsError, 
        setNotificationsLoading,
        setDeletNotificationsLoading
    } from '../redux/slices/notification';

interface Notifications {
    message: string;
    project_id: string;
    short_description: string;
    success: boolean;
}


const useNotificationService = () => {
    const dispatch = useDispatch();
    const fetchNotifications = async (org_id:any) => {
        dispatch(setNotificationsLoading(true));
        try {
            const response = await axios.get<Notifications[]>(`/notification/organization/${org_id}`);
            dispatch(setNotifications(response.data));
        } catch (error) {
            dispatch(setNotificationsError(error));
        } finally {
            dispatch(setNotificationsLoading(false));
        }
    };

    const readNotification = async (noti_id:any) => {
        dispatch(setNotificationsLoading(true));
        try {
            await axios.put<Notifications[]>(`/notification/${noti_id}/read`);
        } catch (error) {
            dispatch(setNotificationsError(error));
        } finally {
            dispatch(setNotificationsLoading(false));
        }
    };
    const deleteNotification = async (noti_id:any,org_id?:any) => {
        dispatch(setDeletNotificationsLoading(true));
        try {
           const response = await axios.delete<Notifications[]>(`/notification/${noti_id}/delete`);
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            dispatch(setNotificationsError(error));
        } finally {
            dispatch(setDeletNotificationsLoading(false));
        }
    };

    return { fetchNotifications, readNotification,deleteNotification };
};

export default useNotificationService;