import { createSlice } from '@reduxjs/toolkit';

export const resourceSlice = createSlice({
    name: 'resources',
    initialState: {
        data: [],
        loading: false,
        error: null,
        showResourcesEdit:false,
        resourcesbyIdsLoading:false,
        resourcesbyIdsError:null,
        resourcesData: {},
        skill_resources: [],
        skill_resources_Loading:false,
        skill_resources_Error:null,
        capacityPlanningData:{},
        suggestedTasks:{},
        unassignedTasksLoading:false,
        unassignedTasksError:null,
        unassignedTasksList:[],
        uatl:[],
    },
    reducers: {
        setResources: (state, action) => {
            state.data = action.payload
        },
        setResourcesLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setResourcesError: (state: any, action) => {
            state.error = action.payload
            state.capacityPlanningData = []
        },
        setShowResourcesEdit: (state: any, action) => {
            state.showResourcesEdit = action.payload
        },
        setResourcesbyIdsLoading: (state: any, action) => {
            state.resourcesbyIdsLoading = action.payload
        },
        setResourcesbyIdsError: (state: any, action) => {
            state.resourcesbyIdsError = action.payload
        },
        setResourcesbyIds: (state: any, action) => {
            state.resourcesData = {...state.resourcesData,
                ...action.payload
            }
        },
        setResourcesbySkill: (state: any, action) => {
            state.skill_resources = action.payload.resources
        },
        setResourcesbySkillLoading: (state: any, action) => {
            state.skill_resources_Loading = action.payload
        },
        setResourcesbySkillError: (state: any, action) => {
            state.skill_resources_Error = action.payload
        },
        setCapacityPlanningRes: (state, action) => {
            state.capacityPlanningData = action.payload
        },
        setSuggestedTasksRes: (state, action) => {
            state.suggestedTasks = action.payload
        },
        setResetUnassignedTasks: (state) => {
            state.capacityPlanningData = []
        },
        setUnassignedTasksLoading: (state: any, action) => {
            state.unassignedTasksLoading = action.payload
        },
        setUnassignedTasksError: (state: any, action) => {
            state.unassignedTasksError = action.payload
            state.capacityPlanningData = []
        },
        setUnassignedTasks: (state, action) => {
            state.unassignedTasksList = action.payload
        },
        setUnassignedAdditionalTasks: (state, action) => {
            state.uatl = action.payload
        },
    }
});

export const { 
    setResources, setResourcesLoading, 
    setResourcesError, setShowResourcesEdit,
    setResourcesbyIdsLoading, setResourcesbyIdsError,
    setResourcesbyIds,setResourcesbySkill,
    setResourcesbySkillLoading,setResourcesbySkillError,
    setCapacityPlanningRes,
    setUnassignedTasksLoading,setUnassignedTasksError,
    setUnassignedTasks,setResetUnassignedTasks,
    setSuggestedTasksRes,setUnassignedAdditionalTasks
} = resourceSlice.actions;

export default resourceSlice.reducer;

