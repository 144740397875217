import { createSlice } from '@reduxjs/toolkit';

export const templatesSlice = createSlice({
    name: 'templates',
    initialState: {
        templates: [],
        templatesLoading: false,
        templatesError: null,
        projectData: {},
    },

    reducers: {
        
        setTemplatesRes: (state: any, action) => {
            state.templates= action?.payload
        },
        setTemplatesError: (state: any,action) => {
            state.templatesError = action.payload
        },
        setTemplatesLoading: (state: any, action) => {
            state.templatesLoading= action.payload
        },
        setTemplateByIdRes: (state: any, action) => {
            state.projectData= action.payload
        },
        
        
    }
});

export const { 
    setTemplatesRes, setTemplatesLoading, setTemplatesError,
    setTemplateByIdRes
} = templatesSlice.actions;

export default templatesSlice.reducer;
