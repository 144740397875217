import { createSlice } from '@reduxjs/toolkit';

interface CompanyInfo {
    name: string,
    numOfTeam: string,
    numOfEmployees: string,
    role: string,
    department: string
}

interface OnboardingState {
    currentStep: number,
    isComplete: boolean,
    company: CompanyInfo,
    loading: boolean,
    showjoyride: boolean,
    error: null,
    joyrideStep: number,
}

const initialState: OnboardingState = {
    currentStep: 1,
    isComplete: false,
    company: {
        name: "",
        numOfTeam: "",
        numOfEmployees: "",
        role: "",
        department: ""
    },
    loading: false,
    error: null,
    showjoyride:false,
    joyrideStep:0
}

export const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setOnboardingStep: (state, action) => {
            state.currentStep = action.payload
        },
        setOnboardingComplete: (state, action) => {
            state.isComplete = action.payload
        },
        setOnboardingInfo: (state, action) => {
            state.company = { ...state.company, ...action.payload }
        },
        setOnboardingLoading: (state, action) => {
            state.loading = action.payload
        },
        setOnboardingError: (state, action) => {
            state.error = action.payload
        },
        setShowjoyride: (state, action) => {
            state.showjoyride = action.payload
        },
        setJoyrideStep: (state, action) => {
            state.joyrideStep = action.payload
        }
    }
});

export const { 
        setOnboardingStep, 
        setOnboardingComplete, 
        setOnboardingInfo, 
        setOnboardingLoading, 
        setOnboardingError,
        setShowjoyride,
        setJoyrideStep 
    } = onboardingSlice.actions;

export default onboardingSlice.reducer;
