import {  useDispatch } from 'react-redux';
import { setRoadmap, setRoadmapLoading, 
    setRoadmapError,setProjectMembers,
    setRoadmapsLoading,setRoadmaps,setRoadmapsError,
    setSaveRoadmapLoading,
    setSaveRoadmapError,setSaveRoadmap,
    setSingleRoadmapLoading,setSingleRoadmap,setSingleRoadmapError,
 } from '../redux/slices/roadmap';
 import {
    setTaskListLoading,setTaskList,
} from '../redux/slices/projects';

import axios from '../utils/axiosConfig';
import { notify } from 'react-notify-toast';

const useRoadmapService = (project_id="") => {
    const dispatch = useDispatch();

    const showError  = (error:any) =>{
        if(error?.response?.data?.error){
            // toast.error(error.response.data.error);
            notify.show(error.response.data.error, "error", 3000);
        }
        if(typeof error?.response?.data?.detail === "string" && error?.response?.data?.detail){
            // toast.error(error.response.data.detail);
            notify.show(error.response.data.detail, "error", 3000);
        }
        if(error.message){
            notify.show(error.message, "error", 3000);
        }
        
    }

    const fetchData = async (project_id: any,sim_flag?:boolean,query?:any ) => {
        if(!project_id) return 
        const API_URL = `/simulation/${project_id}/data`
        dispatch(setRoadmapLoading(true));
        try {
            const response = await axios.post(API_URL,{
                ...query,
                sim_flag:sim_flag ? sim_flag : false,
                response_type:"gantt"
                
            });
            const projectRes =  await axios.get(`/project/${project_id}`);
            dispatch(setRoadmap(response.data));
            dispatch(setProjectMembers(projectRes.data.members));
        } catch (error) {
            showError(error)
            dispatch(setRoadmap({}));
            dispatch(setRoadmapError(error));
        } finally {
            dispatch(setRoadmapLoading(false));
        }
    };

    const fetchProjectShareData = async (token: any,sim_flag?:boolean,query?:any ) => {
        if(!token) return 
        const API_URL = `/project/share`
        dispatch(setRoadmapLoading(true));
        dispatch(setTaskListLoading(true));
        try {
            const response = await axios.post(API_URL,{
                response_type:"gantt",
                ...query,
                token,
                sim_flag:sim_flag ? sim_flag : false,
                
                
            },
            {
                ignoreGlobalCatch: true,
            });
            dispatch(setRoadmap(response.data));
            dispatch(setTaskList(response.data));
            // dispatch(setRoadmapCPLI(response.data));
        } catch (error) {
            showError(error)
            dispatch(setRoadmap({}));
            dispatch(setRoadmapError(error));
        } finally {
            dispatch(setRoadmapLoading(false));
            dispatch(setTaskListLoading(false));
        }
    };


    const fetchRoadmaps = async (org_id?: any) => {
        dispatch(setRoadmapsLoading(true));
        try {
            const response =  await axios.get(`/roadmaps`);
            dispatch(setRoadmaps(response.data));
        } catch (error) {
            showError(error)
            dispatch(setRoadmapsError(error));
        } finally {
            dispatch(setRoadmapsLoading(false));
        }
    };

    const fetchSingleRoadmap = async (roadmap_id?: any,sim_flag?:any) => {
        dispatch(setSingleRoadmapLoading(true));
        try {
            const response =  await axios.get(`/roadmap/${roadmap_id}/${sim_flag}`);
            dispatch(setSingleRoadmap(response.data));
        } catch (error) {
            showError(error)
            dispatch(setSingleRoadmapError(error));
        } finally {
            dispatch(setSingleRoadmapLoading(false));
        }
    };

    const downloadRoadmapReport = async (roadmap_id?: any,sim_flag?:any,roadmap_name?:any) => {
        dispatch(setSingleRoadmapLoading(true));
        try {
            const response =  await axios.get(`/roadmap/${roadmap_id}/report/${sim_flag}`,
                {
                    headers:
                    {
                        'Content-Disposition': "attachment; filename=template.xlsx",
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    },
                    responseType: 'arraybuffer',
                }
            );
            let blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${roadmap_name} ${sim_flag ? "Simulation" : ""} - Weekly skill requirement by hours - ${+new Date()}.xlsx`);
            link.click();
        } catch (error) {
            showError(error)
            dispatch(setSingleRoadmapError(error));
        } finally {
            dispatch(setSingleRoadmapLoading(false));
        }
    };

    const saveRoadmap = async (params?:any) => {
        dispatch(setSaveRoadmapLoading(true));
        try {
            const response =  await axios.post(`/roadmap`,params);
            notify.show(response.data.message, "success", 3000);
            await fetchRoadmaps();
            dispatch(setSaveRoadmap(response.data));
        } catch (error) {
            showError(error)
            dispatch(setSaveRoadmapError(error));
        } finally {
            dispatch(setSaveRoadmapLoading(false));
        }
    };
    const updateRoadmap = async (roadmap_id?:any,params?:any) => {
        dispatch(setSaveRoadmapLoading(true));
        try {
            const response =  await axios.put(`/roadmap/${roadmap_id}`,params);
            notify.show(response.data.message, "success", 3000);
            await fetchRoadmaps();
            dispatch(setSaveRoadmap(response.data));
        } catch (error) {
            showError(error)
            dispatch(setSaveRoadmapError(error));
        } finally {
            dispatch(setSaveRoadmapLoading(false));
        }
    };

    const deleteRoadmap = async (roadmap_id?:any) => {
        dispatch(setSaveRoadmapLoading(true));
        try {
            await axios.delete(`/roadmap/${roadmap_id}`);
            await fetchRoadmaps();
            // dispatch(setSaveRoadmap(response.data));
        } catch (error) {
            showError(error)
            dispatch(setSaveRoadmapError(error));
        } finally {
            dispatch(setSaveRoadmapLoading(false));
        }
    };

    return { fetchData,fetchRoadmaps,saveRoadmap,
        deleteRoadmap,
        updateRoadmap,fetchSingleRoadmap,
        downloadRoadmapReport,
        fetchProjectShareData 
    };
};

export default useRoadmapService;