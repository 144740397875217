import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(async function (config:any) {
  let  token = ''
  if(Clerk?.session ){
      token =  await Clerk.session.getToken({template:"main"});
  }
  config.headers.Authorization = `Bearer ${token}`
  return config;
});

axiosInstance.defaults.timeout = 60000;

export default axiosInstance;
