import React, { useState, useEffect,Suspense } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Flowbite } from "flowbite-react";
import {
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";

import Joyride , {STATUS} from 'react-joyride';

import { 
  setShowjoyride, 
  setJoyrideStep
} from './redux/slices/onboarding';

import { SideNav } from "./components/SideNav/index";
// import Loader from './components/Loader';
import { useJwt } from './helpers/common'
import "react-datepicker/dist/react-datepicker.css";

import { useOrganizationService } from './services';

const Inbox = React.lazy(() => import('./pages/Inbox'));
const Resources = React.lazy(() => import('./pages/Resources'));
// const OrgSkills = React.lazy(() => import('./pages/Settings/OrgSkills'));
const Settings = React.lazy(() => import('./pages/Settings'));
// const Jirasettings = React.lazy(() => import('./pages/Settings/Jirasettings'));
// const ModelSettings = React.lazy(() => import('./pages/Settings/ModelSettings'));
// const VectorSettings = React.lazy(() => import('./pages/Settings/VectorSettings'));
const Projects = React.lazy(() => import('./pages/Projects'));
// const EditTask = React.lazy(() => import('./pages/Projects/EditTask'));
const SharePorject = React.lazy(() => import('./pages/Projects/shareProject'));
const RoadMap = React.lazy(() => import('./pages/RoadMap'));

const ChatBotAI = React.lazy(() => import('./pages/ChatBotAI'));
const ChatClient = React.lazy(() => import('./pages/ChatClient'));

const RoadMap2 = React.lazy(() => import('./pages/RoadMapNew'));
const RoadMapDetails = React.lazy(() => import('./pages/RoadMapNew/RoadMapDetails'));
const NotFoundPage = React.lazy(() => import('./pages/Error/404'));
const SideNavProvider = React.lazy(() => import('./context/SideNavContext'));
const ProfileInfo = React.lazy(() => import('./pages/Onboarding/sections/ProfileInfo'));
const OrgInfo = React.lazy(() => import('./pages/Onboarding/sections/OrgInfo'));
const Home = React.lazy(() => import('./pages/Home'));
const JoinWaitList = React.lazy(() => import('./pages/Home/JoinWaitList'));
const InviteTeam = React.lazy(() => import('./pages/Onboarding/sections/InviteTeam'));
const InviteTeams = React.lazy(() => import('./pages/Onboarding/sections/InviteTeams'));
const About = React.lazy(() => import('./pages/Home/About'));
const Privacypolicy = React.lazy(() => import('./pages/Home/Privacypolicy'));
const Licensing = React.lazy(() => import('./pages/Home/Licensing'));
const Contact = React.lazy(() => import('./pages/Home/Contact'));
const Pricing = React.lazy(() => import('./pages/Home/Pricing'));
const SignUp = React.lazy(() => import('./pages/Home/SignUp'));
const ErrorBoundary = React.lazy(() => import('./pages/Error/ErrorBoundary'));
const Templates = React.lazy(() => import('./pages/Templates'));
const TemplatesView = React.lazy(() => import('./pages/Templates/TemplatesView'));

const TourGuideComponent = React.lazy(() => import('./components/TourGuide'));



const steps = [
  {
    target: '.createnewProject',
    content: 'Click On this to Create New Project',
    styles: {
      buttonNext: {
        display: 'none',
      }
    }
  },
  {
    target: '.cadenceAIToggle',
    content: 'Click On this to Create AI Project',
    styles: {
      options: {
        zIndex: 999999,
      },
    },
    option:{
      disableBeacon:true,
    }
  },
  {
    target: '.projectDescription',
    content: 'Add some text here for Project',
    styles: {
      options: {
        zIndex: 999999,
      },
    },
    option:{
      disableBeacon:true,
    }
  },
  {
    target: '.addNewOrgSkills',
    content: 'Click here to add new Skill',
    styles: {
      options: {
        zIndex: 999999,
      },
      buttonNext: {
        display: 'none',
      }
    },
    option:{
      disableBeacon:true,
    }
  },
];



function App() {
  const JWTdata: any = useJwt()
  const [dark, setDark] = useState(true);
  const { showjoyride, joyrideStep } = useSelector((state: any) => state.onboarding)
  const { orgWorkingDays=[],orgWorkingDaysLoading } = useSelector((state: any) => state.organization)
  const { org_id = '' } = JWTdata
  const dispatch = useDispatch();

  const { fetchOrgWorkingDays } = useOrganizationService()

  useEffect(() => {
    const joyride = new URLSearchParams(window.location.search).get("joyride")
    if(localStorage && localStorage.getItem("theme")){
      let dark = localStorage && localStorage.getItem("theme") === "dark"
      setDark(dark);
    } else {
      setDark(true);
    }
    setTimeout(()=>{
      dispatch(setShowjoyride(joyride));
    },2000)
  }, [])


  useEffect(() => {
    if(org_id && localStorage){
        let workingDay:any = localStorage.getItem("workingDays") ? localStorage.getItem("workingDays") : ""
        let workingDays:any = workingDay ? JSON.parse(workingDay) : {};
        if(!workingDays[org_id] && !orgWorkingDays.length && !orgWorkingDaysLoading){
          fetchOrgWorkingDays(org_id)
        } else if(orgWorkingDays.length) {
          workingDays[org_id] = orgWorkingDays
          localStorage.setItem("workingDays",JSON.stringify(workingDays))
        }
    }
  }, [org_id,orgWorkingDaysLoading])


  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type,lifecycle } = data;
    if ((action === "close" || action === "next") && index ===0) {
      dispatch(setShowjoyride(false));
      dispatch(setJoyrideStep(1));
    } else if (action === "close") {
      dispatch(setShowjoyride(false));
    }else  if (action === "prev" && joyrideStep === 2) {
      dispatch(setJoyrideStep(index-1));
    } else if(action === "next" && joyrideStep === 1 ){
       dispatch(setJoyrideStep(joyrideStep+1));
    } else if (lifecycle === 'complete') {
      dispatch(setShowjoyride(false));
    }

  };

  const renderSuspense = () => {
    return (
      <div className="flex justify-center items-center dark:text-white text-gray-500 text-2xl w-full h-[100vh]">
        <img src="https://cadence-fe-bucket.s3.us-east-2.amazonaws.com/images/cadencelogo.webp" className="h-6 sm:h-9 mr-4" alt=" Cadencepro Logo" />
        Welcome to CadenceProAI.
      </div>
    )
  }


  
const location = window.location.href;
  return (
    <div className='max-h-[100%] relative'>
      <Suspense fallback={renderSuspense()}>
        <ErrorBoundary>
          <Flowbite theme={{ dark }}>
            <BrowserRouter>
              <SideNavProvider >
                
                <SignedIn>
                  <Joyride 
                    callback={handleJoyrideCallback}
                    steps={steps} 
                    continuous
                    hideBackButton
                    showSkipButton
                    run={showjoyride}
                    stepIndex={joyrideStep}
                  />
                  <TourGuideComponent />
                  <div style={{ display: "flex" }}>
                    {JWTdata.org_id && !location.includes("onboarding") && !location.includes("signup") && !location.includes("settings") && !location.includes("sharelink")  && <SideNav />}
                    <Routes>
                      <Route path="/" element={<Inbox />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="settings/inviteuser" element={<InviteTeam />} />
                      <Route path="resources" element={<Resources />} />
                      <Route path="projects" element={<Projects />} />
                      <Route path="projects/:project_id" element={<Projects />} />
                      <Route path='roadmapold' element={<RoadMap />} />
                      <Route path='strategicmaps' element={<RoadMap2 />} />
                      <Route path='strategicmaps/:roadmap_id' element={<RoadMapDetails />} />
                      <Route path="onboarding/org" element={<OrgInfo />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="onboarding/profileinfo" element={<ProfileInfo />} />
                      <Route path="setting/inviteteam" element={<InviteTeams />} />
                      <Route path="chatbotai" element={<ChatBotAI />} />
                      <Route path="chatclient" element={<ChatClient />} />
                      <Route path="/projects/:token/sharelink" element={<SharePorject />} />
                      <Route path="templates" element={<Templates />} />
                      <Route path="templates/:template_id" element={<TemplatesView />} />
                      <Route path="*" element={ <NotFoundPage /> } />
                    </Routes> 

                  </div>
                </SignedIn>
                <SignedOut>
                  <div style={{ display: "flex",justifyContent:"center",alignItems:"center" }}>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/pricing" element={<Pricing />} />
                      <Route path="/privacypolicy" element={<Privacypolicy />} />
                      <Route path="/licensing" element={<Licensing />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/joinwaitlist" element={<JoinWaitList />} />
                      <Route path="/projects/:token/sharelink" element={<SharePorject />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>

                  </div>
                </SignedOut>
                
              </SideNavProvider>
            </BrowserRouter>
          </Flowbite>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}

export default App;
