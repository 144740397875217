import { useDispatch } from 'react-redux';
import {
    setProjects, setProjectsLoading, setProjectsError,
    setTasksLoading, setTasksError, setTask, setTaskLoading, setTaskError,
    setAddResourcesLoading,setAddResourcesError,setMappedResources,
    setMappedResourcesError,setMappedResourcesLoading,
    setSaveMappedResourcesLoading,setSaveMappedResourcesError,
    setSimulationMergeLoading,setSimulationMergeError,
    setTaskListLoading,setTaskListError,setTaskList,
    setVersionsLoading,setVersionsError,setVersions,
    setSyncWithJiraLoading,setSyncWithJiraError,
    setUpdateSimulationLoading,setUpdateSimulationError,
    setProjectHistoryLoading,setProjectHistoryError,setProjectHistory,
    setProjectIntegrationsLoading,setProjectIntegrationsError,setProjectIntegrations,
    setProjectStatsLoading,setProjectStats,setProjectStatsError,
    setProjectShare,setProjectShareLoading,setProjectShareError,
    setSingleProjectData,setSingleProjectLoading,setSingleProjectError
} from '../redux/slices/projects';
import { setRoadmapCPLI
 } from '../redux/slices/roadmap';
 import { useRoadmapService,useTemplateService } from '../services';
import axios from '../utils/axiosConfig';
import toast from 'react-hot-toast';
import { notify } from 'react-notify-toast';

import {
    setOrganizationLoading, setOrganizationData, setOrganizationError,
} from '../redux/slices/organization';


interface Dependencies {
    created_at: Date;
    from_task: string;
    item_id: string;
    modified_at: Date;
    project_id: string;
    to_task: string;
}

interface Tasks {
    completion: number;
    created_at: Date;
    duration: number;
    item_id: string;
    modified_at: Date;
    project_id: string;
    responsible: string;
    skill: string;
}

interface Project {
    dependencies: [Dependencies],
    org_id: "",
    project_id: "",
    project_name: "",
    tasks: [Tasks]
}

interface CreateProject {
    org_id?: string;
    project_name?: string;
    stage?: string;
    members?:any[];
    share_link?:any;
}
interface CreateProjectBySimulation {
    org_id?: string;
    project_description?: string;
}

interface TaskCompletion {
    completion?: number;
    skill?: string;
    description?: string;
    duration?: string;
    responsible?: string;
    start_date?: string;
}
interface CreateTask {
    completion: string;
}

interface Organization {
    org_id: string;
    name: string;
    created_at: Date;
    modified_at: Date;
}

const useProjectService = () => {
    const dispatch = useDispatch();
    const {fetchData} = useRoadmapService()
    const {getTemplateById} = useTemplateService()

    const showError  = (error:any) =>{
        if(error?.response?.data?.error){
            // toast.error(error.response.data.error);
            notify.show(error.response.data.error, "error", 3000);
        }
        if(typeof error?.response?.data?.detail === "string" && error?.response?.data?.detail){
            // toast.error(error.response.data.detail);
            notify.show(error.response.data.detail, "error", 3000);
        }
        if(error.message){
            notify.show(error.message, "error", 3000);
        }
        
    }

    const fetchAllProjects = async (org_id: any) => {
        dispatch(setOrganizationLoading(true));
        try {
            const response = await axios.get<Organization[]>(`/organization/${org_id}/projects`);
            dispatch(setOrganizationData(response.data));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const deleteProjects = async (project_id: string,org_id:string) => {
        dispatch(setOrganizationLoading(true));
        try {
           const  data = {project_id,org_id};
            const config = {
                method: 'delete',
                url: `/project`,
                data
              };
            const response = await axios(config)
            if(response.data){
                await fetchAllProjects(org_id);
                notify.show(response.data.message, "success", 3000);
            }
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };



    const fetchSingleProject = async (project_id: string) => {
        dispatch(setSingleProjectLoading(true));
        try {
            if(!project_id){
                return false;
            }
            const response =  await axios.get<Project[]>(`/project/${project_id}?sim_flag=false`);
            const projData:any = response.data
            dispatch(setSingleProjectData(projData));
        } catch (error) {
            showError(error)
            dispatch(setSingleProjectError(error));
        } finally {
            dispatch(setSingleProjectLoading(false));
        }
    };

    const clearSingleProject = async () => {
        dispatch(setSingleProjectData({}));
    };


    const fetchProject = async (project_id: string,sim_flag:boolean=false) => {
        dispatch(setProjectsLoading(true));
        try {
            if(!project_id){
                return false;
            }
            const response =  await axios.get<Project[]>(`/project/${project_id}?sim_flag=${sim_flag}`);
            const resourceIdsObj:any = {}
            const projData:any = response.data
            if(projData.tasks){
                const data  = projData.tasks.filter((ele:any)=>ele.responsible !=="" && ele.responsible !==null)
                const resourceIds:any[]= data.map((ele:any)=>ele.responsible)
                if(resourceIds.length){
                    const resourcesRes = await axios.post(`/resources/getResourcesInfo`, {resource_id:resourceIds});
                    if(resourcesRes.data){
                        resourcesRes.data.forEach((ele:any)=>{
                            resourceIdsObj[ele.id]= ele
                        })
                    }
                }
                const tasks = [...projData.tasks]
                const maptasksData = tasks.map((ele:any)=>{
                    return {
                        ...ele,
                        resourcesData:resourceIdsObj[ele.responsible]
                    }
                })
                projData.tasks = maptasksData
            }
            dispatch(setProjects(projData));
        } catch (error) {
            showError(error)
            dispatch(setProjectsError(error));
        } finally {
            dispatch(setProjectsLoading(false));
        }
    };

    const createProject = async (project: CreateProject) => {
        dispatch(setProjectsLoading(true));
        try {
            const response:any = await axios.post(`/project`, project);
            if(response.data){
                if(response.data.project_id){
                    window.location.href = `/projects/${response.data.project_id}?isnewproject=true`
                } else {
                    await fetchAllProjects(project?.org_id);
                }
            }
            
        } catch (error) {
            showError(error)
            dispatch(setProjectsError(error));
        } finally {
            dispatch(setProjectsLoading(false));
        }
    };
    const createProjectBySimulation = async (project: CreateProjectBySimulation) => {
        dispatch(setProjectsLoading(true));
        try {
            const response:any = await axios.post(`/project/simulation`, project);
            notify.show(response.data.message, "success", 3000);
            if(response.data){
                if(response.data.project_id){
                    window.location.href = `/projects/${response.data.project_id}`
                } else {
                    await fetchAllProjects(project?.org_id);
                }
                
            }
            
        } catch (error) {
            showError(error)
            dispatch(setProjectsError(error));
        } finally {
            dispatch(setProjectsLoading(false));
        }
    };
    const createTask = async (project_id: string, task: CreateTask) => {
        dispatch(setTasksLoading(true));
        try {
            await axios.post(`/project/${project_id}/task`, task);
            fetchProject(project_id);
            getTemplateById(project_id);
        } catch (error) {
            showError(error)
            dispatch(setTasksError(error));
        } finally {
            dispatch(setTasksLoading(false));
        }
    };

    const updateProject = async (project_id: string, project: any) => {
        const { isFetchAllProjects = true } = project;
        if(isFetchAllProjects){
            dispatch(setProjectsLoading(true));
        }
        try {
            const response = await axios.put(`/project/${project_id}`, project);
            if(isFetchAllProjects){
                notify.show(response.data.message, "success", 3000);
                await fetchAllProjects(project.org_id);
            }
        } catch (error) {
            showError(error)
            dispatch(setProjectsError(error));
        } finally {
            dispatch(setProjectsLoading(false));
        }
    };

    const updateTaskCompletion = async (project_id: string, task_id: string, payload: TaskCompletion) => {
        dispatch(setProjectsLoading(true));
        try {
            const response  = await axios.put(`/project/${project_id}/task/${task_id}`, payload);
            if(response.data.error){
                toast.error(response.data.error);
            } else {
                notify.show(response.data.message, "success", 3000);
                fetchProject(project_id);
                getTemplateById(project_id);
            }
            
        } catch (error) {
            showError(error)
            dispatch(setProjectsError(error));
        } finally {
            dispatch(setProjectsLoading(false));
        }
    };

    const fetchTask = async (project_id: string,task_id?:string,sim_flag?:boolean) => {
        dispatch(setTaskLoading(true));
        try {
            const respons =  axios.get<Project[]>(`/project/${project_id}/task/${task_id}?sim_flag=${sim_flag}`);
            const response =  await respons 
            dispatch(setTask(response.data));
        } catch (error) {
            showError(error)
            dispatch(setTaskError(error));
        } finally {
            dispatch(setTaskLoading(false));
        }
    };

    const deleteTask = async (project_id: string,task_id?:string,sim_flag?:any) => {
        dispatch(setTaskLoading(true));
        try {
            const response =  await axios.delete(`/project/${project_id}/task/${task_id}?sim_flag=${sim_flag}`);
            if(response.data.error){
                toast.error(response.data.error);
            } else {
                notify.show(response.data.message, "success", 3000);
                fetchProject(project_id);
                getTemplateById(project_id);
            }
        } catch (error) {
            showError(error)
            dispatch(setTaskError(error));
        } finally {
            dispatch(setTaskLoading(false));
        }
    };
    
    const addResources = async (project_id: string, resource:any) => {
        dispatch(setAddResourcesLoading(true));
        try {
            await axios.post(`/project/${project_id}/resource`, resource);
            fetchProject(project_id);
        } catch (error) {
            showError(error)
            dispatch(setAddResourcesError(error));
        } finally {
            dispatch(setAddResourcesLoading(false));
        }
    };
    const getMappedResources = async (project_id: string, query:any) => {
        dispatch(setMappedResourcesLoading(true));
        try {
            const response = await axios.post(`/project/${project_id}/map_members`, query);
            dispatch(setMappedResources(response.data.members));
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            showError(error)
            dispatch(setMappedResourcesError(error));
        } finally {
            dispatch(setMappedResourcesLoading(false));
        }
    };

    const saveMappedResources = async (project_id: string, query:any) => {
        dispatch(setSaveMappedResourcesLoading(true));
        try {
            const response = await axios.post(`/project/${project_id}/members`, query);
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            showError(error)
            dispatch(setSaveMappedResourcesError(error));
        } finally {
            dispatch(setSaveMappedResourcesLoading(false));
        }
    };

    const saveSimulationMerge = async (project_id: string,sim_flag?:any) => {
        dispatch(setSimulationMergeLoading(true));
        try {
            const response = await axios.post(`/simulation/${project_id}/merge`);
            fetchProject(project_id,sim_flag);
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            showError(error)
            dispatch(setSimulationMergeError(error));
        } finally {
            dispatch(setSimulationMergeLoading(false));
        }
    };

    const sendSimulationReset = async (project_id: string,sim_flag?:any) => {
        dispatch(setSimulationMergeLoading(true));
        try {
            const response = await axios.delete(`/simulation/${project_id}/reset`);
            fetchProject(project_id,sim_flag);
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            showError(error)
            dispatch(setSimulationMergeError(error));
        } finally {
            dispatch(setSimulationMergeLoading(false));
        }
    };

    const fetchTaskData = async (project_id: any,sim_flag?:boolean,query?:any ) => {
        if(!project_id) return 
        const API_URL = `/simulation/${project_id}/data`
        dispatch(setTaskListLoading(true));
        try {
            const response = await axios.post(API_URL,{
                ...query,
                sim_flag:sim_flag ? sim_flag : false,
                response_type:"list"
                
            });
            dispatch(setTaskList(response.data));
            dispatch(setRoadmapCPLI(response.data));
        } catch (error) {
            showError(error)
            dispatch(setTaskListError(error));
        } finally {
            dispatch(setTaskListLoading(false));
        }
    };

    const fetchProjectVersion= async (project_id: string) => {
        dispatch(setVersionsLoading(true));
        try {
            const respons =  axios.get<Project[]>(`/project/${project_id}/versions`);
            const response =  await respons 
            dispatch(setVersions(response.data));
        } catch (error) {
            showError(error)
            dispatch(setVersionsError(error));
        } finally {
            dispatch(setVersionsLoading(false));
        }
    };

    const syncWithJira= async (project_id: string, query?:any) => {
        dispatch(setSyncWithJiraLoading(true));
        try {
            const respons =  axios.post<any>(`/project/${project_id}/sync_jira`,query);
            const response =  await respons;
            notify.show(response.data.message, "success", 3000); 
        } catch (error) {
            showError(error)
            dispatch(setSyncWithJiraError(error));
        } finally {
            dispatch(setSyncWithJiraLoading(false));
        }
    };
    const syncWithAsana= async (project_id: string, query?:any) => {
        dispatch(setSyncWithJiraLoading(true));
        try {
            const respons =  axios.post<any>(`/project/${project_id}/sync_asana`,query);
            const response =  await respons;
            notify.show(response.data.message, "success", 3000); 
        } catch (error) {
            showError(error)
            dispatch(setSyncWithJiraError(error));
        } finally {
            dispatch(setSyncWithJiraLoading(false));
        }
    };

    const updateProjectDescription= async (query?:any) => {
        dispatch(setUpdateSimulationLoading(true));
        try {
            const respons =  axios.post<Project[]>(`/project/simulation/update`,query);
            const response =  await respons;
            notify.show(response.data.message, "success", 3000); 
        } catch (error) {
            showError(error)
            dispatch(setUpdateSimulationError(error));
        } finally {
            dispatch(setUpdateSimulationLoading(false));
        }
    };

    const fetchProjectHistory= async (project_id: string) => {
        dispatch(setProjectHistoryLoading(true));
        try {
            const respons =  axios.get<Project[]>(`/project/${project_id}/project_history`);
            const response =  await respons 
            dispatch(setProjectHistory(response.data));
        } catch (error) {
            showError(error)
            dispatch(setProjectHistoryError(error));
        } finally {
            dispatch(setProjectHistoryLoading(false));
        }
    };
    const fetchProjectIntegrations= async (project_id: string) => {
        dispatch(setProjectIntegrationsLoading(true));
        try {
            const respons =  axios.get<Project[]>(`/project/${project_id}/integrations`);
            const response =  await respons 
            dispatch(setProjectIntegrations(response.data));
        } catch (error) {
            showError(error)
            dispatch(setProjectIntegrationsError(error));
        } finally {
            dispatch(setProjectIntegrationsLoading(false));
        }
    };

    const fetchProjectStats= async (project_id: string,isnewproject:any = "false") => {
        dispatch(setProjectStatsLoading(true));
        try {
            const respons =  axios.get<Project[]>(`/project/${project_id}/stats?is_newproject=${isnewproject}`);
            const response =  await respons 
            dispatch(setProjectStats(response.data));
        } catch (error) {
            showError(error)
            dispatch(setProjectStatsError(error));
        } finally {
            dispatch(setProjectStatsLoading(false));
        }
    };
    const fetchProjectShareTokens= async (project_id:any) => {
        dispatch(setProjectShareLoading(true));
        try {
            const respons =  axios.get<Project[]>(`/project/${project_id}/sharelink`);
            const response =  await respons 
            dispatch(setProjectShare(response.data));
        } catch (error) {
            showError(error)
            dispatch(setProjectShareError(error));
        } finally {
            dispatch(setProjectShareLoading(false));
        }
    };

    const handleVersionStore= async (version_id?:any,projectId?:any,sim_flag?:any) => {
        dispatch(setVersionsLoading(true));
        try {
            const respons = axios.post<Project[]>(`/simulation/${projectId}/restore`,{version_id});
            const response =  await respons;
                notify.show(response.data.message, "success", 3000); 
                await fetchData(projectId,sim_flag,{})
        } catch (error) {
            showError(error)
        } finally {
            dispatch(setVersionsLoading(false));
        }
    };
    const copyProject = async (org_id:String,payload:any) => {
        dispatch(setOrganizationLoading(true));
        try {
            const response = await axios.post<any>(`/project/${payload?.project_id}/copy`,payload);
            if(response.data){
                notify.show(response.data.message, "success", 3000); 
                await fetchAllProjects(org_id);
            }
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const syncWithAzure= async (project_id: string, query?:any) => {
        dispatch(setSyncWithJiraLoading(true));
        try {
            const respons =  axios.post<any>(`/project/${project_id}/sync_azure`,query);
            const response =  await respons;
            notify.show(response.data.message, "success", 3000); 
        } catch (error) {
            showError(error)
            dispatch(setSyncWithJiraError(error));
        } finally {
            dispatch(setSyncWithJiraLoading(false));
        }
    };

    const syncWithMonday= async (project_id: string, query?:any) => {
        dispatch(setSyncWithJiraLoading(true));
        try {
            const respons =  axios.post<any>(`/project/${project_id}/sync_monday`,query);
            const response =  await respons;
            notify.show(response.data.message, "success", 3000); 
        } catch (error) {
            showError(error)
            dispatch(setSyncWithJiraError(error));
        } finally {
            dispatch(setSyncWithJiraLoading(false));
        }
    };



    return { 
        fetchProject, createProject, updateProject, 
        updateTaskCompletion, createTask, createProjectBySimulation, 
        fetchTask,deleteTask,deleteProjects, addResources,
        getMappedResources,saveMappedResources,
        saveSimulationMerge,sendSimulationReset,fetchTaskData,fetchProjectVersion,
        syncWithJira,updateProjectDescription,fetchProjectHistory,fetchProjectIntegrations,
        syncWithAsana,fetchProjectStats,fetchProjectShareTokens,handleVersionStore,
        copyProject,fetchSingleProject,clearSingleProject,syncWithAzure,syncWithMonday
     };
};

export default useProjectService;