import jwt from 'jwt-decode'
import { useEffect, useState } from "react";
import { useAuth } from '@clerk/clerk-react';

export const useJwt = () => {
    const { getToken } = useAuth();
    const [JWTData, setJWtJson] = useState({})

    useEffect(() => {
        const gteJWT = async () => {
            const token: string | null = await getToken({ template: 'main' });
            const data: string = jwt(token ?? "");
            setJWtJson(data)
        }

        gteJWT();
    }, [getToken])

    return JWTData;
}