import { configureStore } from '@reduxjs/toolkit';
import inboxReducer from './slices/inbox';
import organizationReducer from './slices/organization';
import roadmapReducer from './slices/roadmap'
import projectsReducer from './slices/projects'
import onboardingReducer from './slices/onboarding';
import notificationReducer from './slices/notification';
import resourcesReducer from './slices/resources';
import chatMessageReducer from './slices/chat';
import templatesReducer from './slices/templates';

export default configureStore({
    reducer: {
        inbox: inboxReducer,
        organization: organizationReducer,
        roadmap: roadmapReducer,
        projects: projectsReducer,
        onboarding: onboardingReducer,
        notification: notificationReducer,
        resources: resourcesReducer,
        chatMessages: chatMessageReducer,
        templates: templatesReducer
    },
})