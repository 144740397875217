import { createSlice } from '@reduxjs/toolkit';

export const inboxSlice = createSlice({
    name: 'inbox',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {
        setMessages: (state, action) => {
            state.data = action.payload
        },
        setMessagesLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setMessagesError: (state: any, action) => {
            state.error = action.payload
        }
    }
});

export const { setMessages, setMessagesLoading, setMessagesError } = inboxSlice.actions;

export default inboxSlice.reducer;

